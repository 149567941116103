* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

html,
body {
	max-width: 100vw;
	overflow-x: hidden;
	background-color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
	color: inherit;
	border: none;
	font-family: inherit;
	cursor: pointer;
}
button:disabled {
	opacity: 0.75;
	cursor: not-allowed;
}

.hidden {
	display: none !important;
}